@tailwind base;
@tailwind components;
@tailwind utilities;

/* HTML: <div class="loader"></div> */
// .loader {
//   width: 30px;
//   aspect-ratio: 19;
//   position: relative;
//   top: -3px;
// }

// .loader:before,
// .loader:after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin: -8px 0 0 -8px;
//   width: 16px;
//   aspect-ratio: 1;
//   background: #3FB8AF;
//   animation:
//     l1-1 2s infinite,
//     l1-2 .5s infinite;
// }

// .loader:after {
//   background: #FF3D7F;
//   animation-delay: -1s, 0s;
// }

// @keyframes l1-1 {
//   0% {
//     top: 0;
//     left: 0
//   }

//   25% {
//     top: 100%;
//     left: 0
//   }

//   50% {
//     top: 100%;
//     left: 100%
//   }

//   75% {
//     top: 0;
//     left: 100%
//   }

//   100% {
//     top: 0;
//     left: 0
//   }
// }

@keyframes l1-2 {
  80%,
  100% {
    transform: rotate(0.5turn);
  }
}

input,
textarea {
  font-size: max(1em, 16px);
}

input[type="text"],
input[type="number"],
textarea {
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Arthura";
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SOFTSUGAR FADE";
  font-weight: 700;
  font-style: normal;
  src: url("../public/fonts/SOFTSUGARFADE.woff2") format("woff2");
}

@font-face {
  font-family: "Chalk";
  font-weight: 700;
  font-style: normal;
  src: url("../public/fonts/PWChalk.woff2") format("woff2");
}

@font-face {
  font-family: "Scratch";
  font-weight: 700;
  font-style: normal;
  src: url("../public/fonts/Scratch-SM-Bold.woff2") format("woff2");
}

.join_button {
  background: url("./images/join_button.png") no-repeat;
  background-size: 100%;
  cursor: pointer;
  height: 43px;
  overflow: hidden;
  width: 106px;
  text-indent: -9999px;

  &:hover {
    filter: hue-rotate(263deg);
  }
}

.new_game {
  background: url("./images/new_game_button.png") no-repeat;
  background-size: 100%;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  aspect-ratio: 935 / 257;
  margin-bottom: 20px;
  text-indent: -9999px;
  min-height: 46px;

  &:hover {
    filter: hue-rotate(263deg);
  }
}

.player_name {
  font-family: "SOFTSUGAR FADE";
  color: #e2ff08;
  filter: drop-shadow(0px 0px 1px #0d220f);
  text-shadow: 0 0 #ffffff2e;
  -webkit-text-stroke: 1px #0ee2ff08;
}

.game_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(218px, auto));
  gap: 0.5rem;

  &__status {
    position: absolute;
    display: none;
    aspect-ratio: 1 / 1;
    background: url("./images/status_indicator.png") no-repeat;
    background-size: 100%;
    left: -30px;
    width: 45px;

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      left: 53%;
      top: 45%;
    }

    &--active {
      display: block;
      background-position-y: 0%;
      filter: drop-shadow(-5px 0px 7px #4effff7e);

      &:before {
        filter: drop-shadow(0px 0px 7px #6affff);
        background-color: #3bffff55;
      }
    }

    &--inactive {
      display: block;
      background-position-y: 100%;
      filter: drop-shadow(-5px 0px 7px #ff4e1e7e);

      &:before {
        filter: drop-shadow(0px 0px 7px #ff4e1e);
        background-color: #ff4e1e55;
      }
    }
  }
}

.join_panel {
  background: url("./images/wood_panel_3.png") no-repeat;
  background-position-y: top;
  background-size: 100%;
  display: flex;
  padding: 0px;
  color: white;
  padding-right: 0;
  justify-content: space-between;
  padding-left: 20px;
  aspect-ratio: 729 / 136;
  align-items: center;
  text-shadow: 0 0 7px black;
  max-width: clamp(380px, 411px, 100%);
  position: relative;
  font-family: "CHALK";
  margin-top: 25px;

  span {
    font-size: 1.5rem;
    filter: brightness(1) drop-shadow(0 1px 0 black);
  }
}

.heads {
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  top: -45px;
  left: 0px;
  z-index: -1;

  .heads_icon {
    background: url("./images/heads.png") no-repeat;
    width: 35px;
    aspect-ratio: 3 / 4;
    background-size: 980%;
    background-position-y: bottom;
    filter: drop-shadow(0px 0px 2px black);
    position: absolute;

    &--icon {
      position: absolute;
      left: 0px;
      top: 0px;

      &_0 {
        background-position-x: 0px;
        left: 0px;
        z-index: 1;
      }
      &_1 {
        background-position-x: -40px;
        left: 20px;
        z-index: 2;
      }
      &_2 {
        background-position-x: -80px;
        left: 40px;
        z-index: 3;
      }
      &_3 {
        background-position-x: -114px;
        left: 60px;
        z-index: 4;
      }
      &_4 {
        background-position-x: -153px;
        left: 80px;
        z-index: 5;
      }
      &_5 {
        background-position-x: -184px;
        left: 100px;
        z-index: 6;
      }
      &_6 {
        background-position-x: -215px;
        left: 120px;
        z-index: 7;
      }
      &_7 {
        background-position-x: -245px;
        left: 140px;
        z-index: 8;
      }
      &_8 {
        background-position-x: -275px;
        left: 160px;
        z-index: 9;
      }
      &_9 {
        background-position-x: -310px;
        left: 180px;
        z-index: 10;
      }
    }
  }
}

.wood_panel {
  &__top {
    background: url("./images/wood_panels_wide.png") repeat-x;
    // aspect-ratio: 1366 / 76;
    // background-size: 100%;
    // background-position-y: top;

    background-position-y: top;
    background-size: 944px;
    height: 25px;
    box-shadow: 0px 4px 8px 0px #000000;
    z-index: 1;
  }

  &__bottom {
    background: url("./images/wood_panels_wide.png") repeat-x;
    // aspect-ratio: 1366 / 81;
    // background-size: 100%;
    background-position-y: bottom;
    background-size: 944px;
    height: 25px;
    box-shadow: 0px -4px 8px 0px #000000;
    z-index: 1;
  }
}

.round_over {
  &__container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  &__image {
    flex-grow: 1;
    max-width: 450px;
  }
}

.button {
  &__play-again {
    background: url("./images/play_again_button.png") no-repeat;
    background-size: 100%;
    height: 86px;
    overflow: hidden;
    text-indent: -999px;
    width: 212px;

    &:hover {
      background-position-y: 100%;
    }
  }

  &__submit {
    background: url("./images/submit_button.png") no-repeat;
    background-size: 100%;
    width: 106px;
    height: 35px;
    text-indent: -999px;
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(-20%, -50%);

    &:hover {
      background-position-y: 100%;
      height: 39px;
    }
  }
}

.container {
  align-items: center;
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 2rem;
}

.logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 383 / 432;
  background: url("./images/pic_me.png") no-repeat;
  background-size: 100%;
  background-position: center;
}

.image {
  z-index: 0;
  position: relative;

  &__container {
    position: relative;
    padding: 25px;
    padding-bottom: 54px;
  }

  &__new-border {
    border-image: url("./images/new_frame.png") round;
    border-image-slice: 20% 18% 31% 18%;
    border-image-width: 45px 40px 70px 38px;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  &__border-corner {
    border-image: url("./images/border_corners.png") round;
  }

  &__border-decoration--horizontal {
    border-image: url("./images/horizontal_border_decoration.png") round;
  }

  &__border--horizontal {
    border-image: url("./images/horizontal_border_part.png") round;
  }

  &__border-decoration--top {
    border-image: url("./images/top_border_decoration.png") round;
  }

  &__border--vertical {
    border-image: url("./images/vertical_border_part.png") round;
  }

  &__border {
    border-image: url("./images/image_border_hd.png") round;
    border-image-slice: 20% 18% 31% 18%;
    border-image-width: 45px 40px 70px 38px;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: drop-shadow(1px 3px 6px black);
    pointer-events: none;
  }
}

.textfield {
  &__container {
    display: block;
    width: 50%;
    aspect-ratio: 80 / 40;
    border-image: url("./images/textfield_background.png") round;
    border-image-slice: 40% 9% fill;
    border-image-width: 14px;
    background: transparent;
    width: 80vw;
    aspect-ratio: 80 / 40;

    @media screen and (min-width: 768px) {
      width: 50vw;
    }

    @media screen and (min-width: 1024px) {
      width: 40vw;
    }

    &:focus {
      outline: 0;
      border-color: transparent;
      box-shadow: none;
    }
  }

  &__input {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
  }
}

.loader {
  position: relative;
  aspect-ratio: 1 / 1;
  width: 180px;
  transform: scale(0.75);

  &__background {
    background: url("./images/loading_indicator.png") no-repeat;
    aspect-ratio: 1 / 1;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: go 5s linear infinite;
    animation-fill-mode: forwards;
    animation-direction: alternate;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 99%;
    font-weight: 900;
    background: white;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid black;
  }
}

@keyframes go {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gameplay {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.game {
  &__join-code {
    @media screen and (min-width: 1024px) {
      // display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 200px;
    }
  }

  &__facts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--item {
      display: block;
      border-image: url("./images/textfield_background.png") round;
      border-image-slice: 40% 9% fill;
      border-image-width: 14px;
      background: transparent;
      padding: 20px;
    }
  }

  &__guessing {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &--time {
      font-size: 1.5em;
      font-weight: 900;
      color: #ff3d7f;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__background {
    background-image: url("./images/background.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
  }

  &__loading-totem {
    background-image: url("./images/loading_totem.png");
    background-size: 100%;
    width: 230px;
    height: 378px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: bottom;
    position: absolute;
    bottom: -30px;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, 0);

    &:before {
      content: "";
      display: block;
      background-image: url("./images/loading_totem.png");
      background-size: 100%;
      width: 140px;
      height: 230px;
      position: relative;
      background-position: bottom;
      z-index: 2000;
    }
  }

  &__loading-background {
    background-image: url("./images/loading_background.png");
    background-size: 100px;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text-container {
    background-image: url("./images/wood_text_container.png");
    background-size: 100%;
    background-repeat: no-repeat;
    aspect-ratio: 700 / 90;
    padding-top: calc(73 / 1711 * 100%);
    position: relative;
    padding-left: calc(4 / 121 * 100%);
  }

  &__text-input {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
  }

  &__hanging-panel {
    background: url("./images/hanging_banner.png") no-repeat;
    aspect-ratio: 700 / 256;
    background-size: 100%;
    background-position-y: top;
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min(4vw, 33px);
  }

  &__image-container {
    // position: relative;
    // transform: translateY(-25%) scale(2);

    &--background {
      background: url("./images/image_screen.png") no-repeat;
      aspect-ratio: 1/1;
      background-size: 100%;
      background-position-y: 50px;
      margin: 0;
      background-position-x: center;
      position: relative;
      /* z-index: 45; */
      /* left: 0; */
      /* right: 0; */
      /* top: 0; */
      /* bottom: 0; */
      /* background-repeat: repeat; */
      display: block;
      z-index: 10;
      transform: translateX(-1.5%);
    }

    &--image {
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      // padding: 27%;
      // padding-top: calc(34% + 30px);
      padding: 5%;

      img {
        aspect-ratio: 1 / 1;
      }
    }
  }
}
